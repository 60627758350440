import { BaseModel } from "../base.model";
import { DBFactory } from "classes/database/db_factory";
import { ModuleCapability } from "./module.model";
import { FeatureCapability } from "./feature.model";
import { QuantityLimitCapability } from "./quantity-limit.model";
import { CapabilityType } from "types/enums/CapabilityType.enum";
import { CapabilityDataType } from "types/enums/CapabilityDataType.enum";

export type Capabilities = Capability[];

type CapabilityConstructorParams = {
  id: string;
  key: string;
  applicationCapabilityDefinitionId: string;
  parentCapabilityId?: string;
  label: string;
  description: string;
  type: CapabilityType;
  dataType: CapabilityDataType;
  inheritPackageQuantity?: boolean;
  requiresActiveSubscription?: boolean;
  capabilities?: Capabilities;
};

export class Capability extends BaseModel {
  id: string;
  key: string;
  applicationCapabilityDefinitionId: string;
  parentCapabilityId?: string;
  label: string;
  description: string;
  type: CapabilityType;
  dataType: CapabilityDataType;
  inheritPackageQuantity: boolean;
  requiresActiveSubscription: boolean;
  capabilities?: Capabilities;

  constructor(params: CapabilityConstructorParams) {
    super(params);
    this.id = params.id;
    this.key = params.key;
    this.applicationCapabilityDefinitionId =
      params.applicationCapabilityDefinitionId;
    this.parentCapabilityId = params.parentCapabilityId;
    this.label = params.label;
    this.description = params.description;
    this.type = params.type;
    this.dataType = params.dataType;
    this.inheritPackageQuantity = params.inheritPackageQuantity ?? false;
    this.requiresActiveSubscription =
      params.requiresActiveSubscription ?? false;
    this.capabilities = params.capabilities;
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.id,
      key: this.key,
      applicationCapabilityDefinitionId: this.applicationCapabilityDefinitionId,
      parentCapabilityId: this.parentCapabilityId,
      label: this.label,
      description: this.description,
      type: this.type,
      dataType: this.dataType,
      requiresActiveSubscription: this.requiresActiveSubscription,
      inheritPackageQuantity: this.inheritPackageQuantity,
    };
  }

  static fromMap(map: any): Capability {
    return new Capability(map);
  }

  flattenCapabilities(): Capabilities {
    const allCapabilities = this.capabilities ?? [];
    const capabilities = [];

    for (var capability of allCapabilities) {
      capabilities.push(capability);
      capabilities.push(...capability.flattenCapabilities());
    }

    return capabilities;
  }

  async save() {
    this.id = this.key;

    this.modelDatabaseConfig = {
      collection: `/applicationCapabilityDefinitions/${this.applicationCapabilityDefinitionId}/capabilities`,
      path: `/applicationCapabilityDefinitions/${this.applicationCapabilityDefinitionId}/capabilities/${this.id}`,
    };

    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.modelDatabaseConfig);

    if (this.capabilities) {
      for (let capability of this.capabilities) {
        capability.applicationCapabilityDefinitionId =
          this.applicationCapabilityDefinitionId;
        capability.parentCapabilityId = this.id;
        await capability.save();
      }
    }
  }
}
