export enum CapabilityKey {
  basicGrading = "basic-grading",
  chooseInk = "choose-ink",
  classroomManager = "classroom-manager",
  quickComment = "quick-comment",
  quickCommentLimit = "quick-comment-limit",
  submissionLimit = "submission-limit",
  classroomLimit = "classroom-limit",
  thirdPartyLMS = "third-party-lms",
  unlimitedQuickComments = "unlimited-quick-comments",
  unlimitedSubmissions = "unlimited-submissions",
  unlimitedClassrooms = "unlimited-classrooms",
  extraSubmissions = "extra-submissions",
}

export const CapabilityKeys = [
  CapabilityKey.basicGrading,
  CapabilityKey.chooseInk,
  CapabilityKey.classroomManager,
  CapabilityKey.quickComment,
  CapabilityKey.quickCommentLimit,
  CapabilityKey.submissionLimit,
  CapabilityKey.classroomLimit,
  CapabilityKey.thirdPartyLMS,
  CapabilityKey.unlimitedQuickComments,
  CapabilityKey.unlimitedSubmissions,
  CapabilityKey.unlimitedClassrooms,
  CapabilityKey.extraSubmissions,
];
