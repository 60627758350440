import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";
import { Capabilities, Capability } from "./capability.model";
import { CapabilityStatus } from "types/enums/CapabilityStatus.enum";
import { CapabilityKey } from "~/types/enums/CapabilityKeys.enum";
import { as } from "vitest/dist/reporters-5f784f42.js";

export type ApplicationCapabilityDefinitions =
  ApplicationCapabilityDefinition[];

type ApplicationCapabilityDefinitionConstructorParams = {
  id: string;
  version: string;
  createdTimestamp: number;
  lastUpdatedTimestamp: number;
  status: CapabilityStatus;
  isPrimary: boolean;
  capabilities?: Capabilities;
};

export class ApplicationCapabilityDefinition extends BaseModel {
  id: string;
  version: string;
  createdTimestamp: number;
  lastUpdatedTimestamp: number;
  status: CapabilityStatus;
  isPrimary: boolean;
  capabilities?: Capabilities;
  flattenedCapabilities: Capabilities;

  constructor(params: ApplicationCapabilityDefinitionConstructorParams) {
    super(params);
    this.id = params.id;
    this.version = params.version;
    this.createdTimestamp = params.createdTimestamp;
    this.lastUpdatedTimestamp = params.lastUpdatedTimestamp;
    this.status = params.status;
    this.isPrimary = params.isPrimary;
    this.capabilities = params.capabilities;

    this.flattenedCapabilities = this.flattenCapabilities();
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.id,
      version: this.version,
      createdTimestamp: this.createdTimestamp,
      lastUpdatedTimestamp: this.lastUpdatedTimestamp,
      status: this.status,
      isPrimary: this.isPrimary,
    };
  }

  static fromMap(map: any) {
    return new ApplicationCapabilityDefinition(map);
  }

  get nestedCapabilities(): Capabilities {
    const allCapabilities = this.capabilities ?? [];
    const capabilities = [];

    // First clear the each capabilities child array
    for (var capability of allCapabilities) {
      capability.capabilities = [];
    }

    for (var capability of allCapabilities) {
      if (capability.parentCapabilityId == undefined) {
        capabilities.push(capability);
      }

      if (capability.parentCapabilityId != undefined) {
        const parentCapability = allCapabilities.find((c: Capability) => {
          return c.id == capability.parentCapabilityId;
        });

        if (parentCapability != undefined) {
          if (parentCapability.capabilities == undefined) {
            parentCapability.capabilities = [];
          }

          parentCapability.capabilities.push(capability);
        }
      }
    }

    return capabilities;
  }

  flattenCapabilities(): Capabilities {
    const flattenedCapabilities = [] as Capabilities;

    for (var capability of this.capabilities ?? []) {
      flattenedCapabilities.push(capability);

      if (capability.capabilities) {
        for (var childCapability of capability.capabilities) {
          flattenedCapabilities.push(childCapability);
          flattenedCapabilities.push(...childCapability.flattenCapabilities());
        }
      }
    }

    return flattenedCapabilities;
  }

  getCapabilityByKey(key: CapabilityKey): Capability | undefined {
    const allCapabilities = this.flattenedCapabilities ?? [];
    return allCapabilities.find((c: Capability) => {
      return c.key == key;
    });
  }

  async save() {
    this.id = this.version;

    this.modelDatabaseConfig = {
      collection: "applicationCapabilityDefinitions",
      path: `applicationCapabilityDefinitions/${this.id}`,
    };

    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.modelDatabaseConfig);

    if (this.capabilities) {
      for (let capability of this.capabilities) {
        capability.applicationCapabilityDefinitionId = this.id;
        await capability.save();
      }
    }
  }
}
